/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var body = $('body');
        // set object fit polyfill on any image with the class of 'object-fit'
        objectFitImages('img.object-fit');

        // set up click handler on clicking the menu button for slide out
        var menu = $('#menu'),
            menuButton = $('.menuButton'),
            links = menuButton.find('span');

        menuButton.on('click', function() {

          menu.toggleClass('active');
          links.toggleClass('open');
          $(this).toggleClass('open');

          if ( menu.hasClass('active') ) {

            var menuTimeline = new TimelineMax({ paused: true }),
                menuCanvas = $('#menu'), menuLinks = $('#menu-primary-navigation').find('>li');

            menuTimeline.staggerFrom(menuLinks, 0.3, {opacity: 0, x: 100, ease:Circ.easeOut }, 0.1);

            $('ul.social-list path').css({transition: 'fill 0.5s ease-in-out', fill: "white"});

            menuTimeline.play();

            if(body.hasClass('scrolled')) {
             // $('#navigation').css({backgroundColor: "transparent", boxShadow: "none"});
              body.removeClass('scrolled');
            }

            if(body.hasClass('home')) {
              $.fn.fullpage.setAllowScrolling(false); // disable fullpage when menu is open on home page.
            }

          } else {

            $('ul.social-list path').css({transition: 'fill 0.5s ease-in-out', fill: "black"});

            if(body.hasClass('home')) {
              $.fn.fullpage.setAllowScrolling(true); // enable fullpage when menu is closed on home page.
            }

            if(!body.hasClass('scrolled') && $(window).scrollTop() > 70 ) {
              // $('#navigation').css({backgroundColor: "transparent", boxShadow: "none"});
              body.addClass('scrolled');
            }

          }

        });

        if(!body.hasClass('home')) {
          $(document).on('scroll', _.throttle( function() {
            if($(window).scrollTop() > 70 && !$('#menu').hasClass('active')) {
              $('body').addClass('scrolled');
            } else {
              $('body').removeClass('scrolled');
            }
          }, 500));
        }

        /**
         * ScrollTop Button Functionality
         */

        var scrollTopButton = $('#scrollToTop');

        function scrollButton(triggerEl) {
          var trigger = $(triggerEl)[0];


          $(document).on('scroll', _.throttle( function() {
            var windowTop = $(window).scrollTop(), // distance window is scrolled from top
              ElTop = $(trigger).offset().top, // distance element's top is from document top.
              ElHeight = $(trigger).height(); // the element's height
            if(windowTop >= (ElTop + ElHeight) ) {
              $('#scrollToTop').addClass('show');
            } else {
              $('#scrollToTop').removeClass('show');
            }
          }, 500));


          $('#scrollToTop').on('click', function() {
            $('html, body').animate({ scrollTop: 0 });
          });

          var tl = new TimelineMax({paused: true}), arrow = $('#scrollToTop g.arrow');

          tl.to($(arrow), 0.2, {y: -10, opacity: 0}).to($(arrow), 0.2, {y: 10}).to($(arrow), 0.2, {y:0, opacity: 1});

          $('#hotspot').on('mouseenter', function() {
            tl.play();
            tl.restart();
          });

        }

        if( scrollTopButton.length ) { // check for the button before running the function

          var arrayCheck = [
            $('header.project-header'),
            $('.partners'),
            $('.category-section')
          ];

          $.each(arrayCheck, function() {
            if(this.length) {
              scrollButton(this);
              return false;
            }
          });

        }

        var reveal = $('.reveal');

        var matchSize = {};

        $(window).on('load resize', _.debounce(function(){
          if(window.matchMedia("(min-width: 768px)").matches) {
            matchSize.matchedSize = "true";
          } else {
            matchSize.matchedSize = "false";
          }
        }, 200));


        function revealShowHide() {
          var trigger = $('img.single-project-thumb'),
            headerMark = trigger.offset().top,
            scrollTopMark = $(window).scrollTop();
          if(scrollTopMark > headerMark) {
            $('footer.main-footer, .reveal').css({opacity: 1});
          } else {
            $('footer.main-footer, .reveal').css({opacity: 0});
          }
        }

        function setUpReveal(state) {
          var revealFirst = reveal[0],
            revealAreaHeight = $(reveal[0]).height(),
            footer = $('footer.main-footer'),
            footerHeight = $('#footer').outerHeight(),
            previous = $('.reveal').prev(),
            wraps = $('.entry-content, .wrap'),
            article = $('article.minday_project');

          if(state === "add") {
            $(wraps).css({zIndex: 1});
            $(revealFirst).css({position: "fixed", bottom: footerHeight, zIndex: -1, opacity: 0});
            $('header.project-header').css({backgroundColor: "white"});
            $(article).css({marginBottom: (revealAreaHeight + footerHeight)});
            $(previous).css({zIndex: 1, backgroundColor: "white", boxShadow: "0px 10px 7px -7px rgba(0,0,0,0.2)", paddingBottom: 40, position: "static"});
            $(footer).css({position: "fixed", bottom: 0, zIndex : -1, opacity: 0});
          } else if(state === "remove") {
            $(wraps).css({zIndex: 1});
            $(revealFirst).css({position: "relative", bottom: "auto", opacity: 1});
            $(article).css({marginBottom: 0});
            $(previous).css({zIndex: 1, backgroundColor: "white", boxShadow: "none", paddingBottom: 40, position: "relative"});
            $(footer).css({position: "relative", bottom: "auto", zIndex : -1, opacity: 1, float:"left"});

          }

        }

        $(window).on('load resize', _.debounce(function(){
          if( reveal.length && matchSize.matchedSize === "true" ) {
            setUpReveal("add");
            $(document).on('scroll', _.throttle(revealShowHide, 50));
          } else {
            setUpReveal("remove");
          }
        }, 200));


        // un-stick scroll button when reaching bottom of page.

        $(window).on('scroll', function() {
          var content, offset;
          if(reveal.length) {
            content = $('#entry-content');
            offset = (content.height() + content.offset().top + 40 ) - ($(window).scrollTop() + $(window).height());

            if(matchSize.matchedSize === "true") {
              margin = parseInt($(content)[0].style.marginBottom) + 40;
            } else {
              margin = 36;
            }

          } else {
            content = $('.main');
            margin = 170;
            offset = (content.height() + content.offset().top + 90 ) - ($(window).scrollTop() + $(window).height());

          }

          //var offset = (content.height() + content.offset().top + 40 ) - ($(window).scrollTop() + $(window).height());

          if( offset <= 0 ) {
            $('#scrollToTop').css({position: "absolute", bottom: margin});
          } else {
            $('#scrollToTop').css({position: "fixed", bottom: "36px"});
          }
        });


        // project tile rollover
        var projectTile = $('.project-tile-container:not(.category)');

        if(projectTile.length && !body.hasClass('projects')) {
          $.each(projectTile, function() {

            var newTimeLine = new TimelineMax({paused : true});

            newTimeLine.to($(this), 0.2, {opacity: 1}).to($(this).find('.project-tile-title'), 0.2, {opacity: 1}, "-=0.1");

            $(this).hover(
              function() {
                newTimeLine.play();
              },
              function() {
                newTimeLine.reverse();
              }
            );

          });
        }
        var revealEl = $('.reveal-title'),
          title = $('.title h1');
        // TweenMax.to(revealEl, 0.5, {scaleX : 0, ease: Power1.easeIn, delay:0.5 });


        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        var sections = $('.storyContentItem'), // all story contents (sections)
          media = $('.itemContainer'), // all media selection
          scrollingSpeed = 700, // scrollspeed var for fullpage-js and tweens
          scrollButton = $('#downScrollButton'),
          scrollButtonUp = $('#upScrollButton'); // svg scroll prompt el


        // scrollButton addclass .arrowAnimation
        if(scrollButton) {
          scrollButton.addClass( "arrow-animation" );
        }

        /**
         * function to set up the tween for whatever the slide animation should be. Pulls from data attr of element
         * @param nextIndex = index of the next section. Needs to be adjusted for 0 index array, fullpage js starts the count at 1.
         */
        function tweenSlide(nextIndex) {
          var dataTarget, tl, targetImage, upComingSection, dataColor;

          upComingSection = $(sections[nextIndex]);
          dataTarget = upComingSection.data('target');
          dataColor = upComingSection.data('color');
          targetImage = $('.itemContainer#' + dataTarget)[0];

          // locate upcoming section and change body color.
          // direction dependant

          if(dataTarget !== undefined) {

            tl = new TimelineMax({delay:(scrollingSpeed / 1000) / 2});

            tl.fromTo(targetImage, (scrollingSpeed / 1000) / 2, {opacity: 0}, {opacity: 1}, "sync")
              .to($(media).not(targetImage), (scrollingSpeed / 1000) / 2, {opacity: 0}, "sync");


          } else if ( dataColor !== undefined ) {

            tl = new TimelineMax();
            var sectionImages = upComingSection.find('img');

            tl.to($(media), (scrollingSpeed / 1000) / 2, {opacity: 0}, "colorChange")
              .to($('body'), (scrollingSpeed / 1000) / 2, {backgroundColor : dataColor}, "colorChange -= .2")
              .fromTo($(sectionImages), (scrollingSpeed / 1000) / 2, {opacity: 0}, {opacity: 1});

          }
        }


        var lethargy = new Lethargy();
        // throttle kinetic scrolling events on home page

        $('body').on('mousewheel DOMMouseScroll wheel MozMousePixelScroll', function(e){
          if(lethargy.check(e) === false) {
            return false;
          }
        });

        // init fullpage
        $('.fullpage').fullpage({
          scrollingSpeed: scrollingSpeed,
          continuousVertical: true,

          afterRender : function() {

            var dataTarget, tl, targetImage;
            tl = new TimelineMax();
            dataTarget = $(sections[0]).data('target');
            targetImage = $('.itemContainer#'+dataTarget)[0];
            tl.to($(targetImage) , (scrollingSpeed / 1000) / 2, {opacity: 1});
          },

          onLeave : function(index, nextIndex, direction) {

            if (index === 1) {
              // scrollButton.css({opacity: 0});
              // scrollButton.off();
              scrollButton.removeClass("arrow-animation");
              scrollButtonUp.css("visibility", "visible");
            }

            TweenMax.to($('body'), ( ( scrollingSpeed / 1000) / 2 ), {backgroundColor: "rgba(0,0,0,1)"});

            tweenSlide(nextIndex - 1);


          }
        });



        var fullPageTimer =  setInterval(function(){
              $.fn.fullpage.moveSectionDown();
            }, 5000);


        // click event for the scrollbutton
        // animation controlled with css @keyframe
        scrollButton.on('click', function() {
          $.fn.fullpage.moveSectionDown();
          // $(this).css({opacity: 0});
          // scrollButton.off();
          scrollButton.removeClass( "arrow-animation" );
          scrollButtonUp.css( "visibility", "visible" );
          clearInterval(fullPageTimer);
        });

        scrollButtonUp.on('click', function() {
          $.fn.fullpage.moveSectionUp();
          clearInterval(fullPageTimer);
        });

        $(document).keydown(function(e) {
          if(e.keyCode === 40 || e.keyCode === 38 || e.keyCode === 32 ) {
            console.log(e);
            clearInterval(fullPageTimer);
          }
        });

        $(document).on('wheel touchstart touchmove', function(e) {
          clearInterval(fullPageTimer);
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // page, note the change from about-us to about_us.
    'projects': {
      init: function() {

        var projectTiles = $('.project-tile-container:not(.category)');

        $.each(projectTiles, function() {

          var timeline = new TimelineMax({paused: true});
          // timeline.to($(this), 0.2, {y: -20})
            // .to($(this).find('.project-tile-title'), 0.2, {border: "5px solid white", paddingTop: "40px", paddingBottom: "40px"})
            // .to($(this).find('.project-tile-title'), 0.2, {paddingLeft: "40px", paddingRight: "40px"});

          $(this).hover(
            function() {
              timeline.play();
            }, function() {
              timeline.reverse();
            }
          );

        });


        // JavaScript to be fired on the featured projects page
      }
    },
    //
    'all_projects': {
      init: function() {

        function checkTotal() {
          var projectCount = ajaxpagination.total,
            currentProjects = $('.project-tile-container').length;

          console.log(projectCount, currentProjects);

          if(projectCount <= currentProjects ) {
            $('#load-more-btn').hide();
          }
        }


        checkTotal();


        $(document).on( 'click', '#load-more-btn', function( event ) {
          event.preventDefault();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'ajax_pagination',
              page: ajaxpagination.page
            },

            beforeSend: function() {

              var loadMoreBtn = $("#load-more-btn"),
                code = '<span> Loading ' +
                  '<span class="glyphicon glyphicon-refresh spinner"></span>' +
                  '</span>';

              loadMoreBtn.addClass('loading');
              loadMoreBtn.removeClass("default");
              loadMoreBtn.html(code);

            },

            success: function( result ) {

              var data = $(result),
                loadMoreBtn = $("#load-more-btn"),
                code;


              // check to make sure anything was returned from the query
              if(data.length) {


                // if there's data
                code = '<span class="animate"> More Projects ' +
                  '<span class="first animation"></span> ' +
                  '<span class="second animation"></span> ' +
                  '</span>';

                loadMoreBtn.addClass("default");
                loadMoreBtn.removeClass("loading");
                loadMoreBtn.html(code);

                $(data).hide();
                // append it to the div
                $("div.result").append(data);
                // fade it in
                $(data).fadeIn();
                // increment the page
                ajaxpagination.page++;

              }

              checkTotal();

            }
          });
        });
      }
    },
    'post_type_archive_minday_recognition': {
      init: function() {

        function checkTotal() {
          var projectCount = ajaxpagination.total,
            currentProjects = $('.cell').length;

          console.log(projectCount, currentProjects);

          if(projectCount <= currentProjects ) {
            $('#load-more-recognition-btn').hide();
          }
        }


        checkTotal();

        $(document).on( 'click', '#load-more-recognition-btn', function( event ) {

          event.preventDefault();

          $.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
              action: 'ajax_pagination_recognition',
              page: ajaxpagination.page
            },

            beforeSend: function() {

              var loadMoreBtn = $("#load-more-recognition-btn"),
                code = '<span> Loading ' +
                  '<span class="glyphicon glyphicon-refresh spinner"></span>' +
                  '</span>';

              loadMoreBtn.addClass('loading');
              loadMoreBtn.removeClass("default");
              loadMoreBtn.html(code);

            },

            success: function( result ) {

              var data = $(result),
                loadMoreBtn = $("#load-more-recognition-btn"),
                code;

              // check to make sure anything was returned from the query
              if(data.length) {

                // if there's data
                code = '<span class="animate"> Load More ' +
                  '<span class="first animation"></span> ' +
                  '<span class="second animation"></span> ' +
                  '</span>';

                loadMoreBtn.addClass("default");
                loadMoreBtn.removeClass("loading");
                loadMoreBtn.html(code);

                $(data).hide();
                // append it to the div
                $("div.result").append(data);
                // fade it in
                $(data).fadeIn();
                // increment the page
                ajaxpagination.page++;
              }

              checkTotal();

            }
          });
        });
      }
    },
    'single_minday_project': {
      init: function() {

        var controller = new ScrollMagic.Controller();

        /**
         * Selections for all the content types on a single project page.
         */

        var headerEls = $('.intro, ul.project-details li, .project-description, .project-shares');
            headerTween = TweenMax.staggerFrom(headerEls, 0.5, {opacity: 0, y: 50}, 0.2);


       var HeaderScene = new ScrollMagic.Scene({
         triggerElement: $('.intro')[0],
         triggerHook: "onEnter",
         offset: 50})
         .setTween(headerTween)
         .addTo(controller);

        var singleImage = $('.image-text'),
            imageInset = $('.image-inset'),
            flipbookAnimation = $('.flipbook-animation'),
            imageFullWidth = $('.image-full-width'),
            imagePullquote = $('.image-pullquote');

        var captions = $('.caption, .quote');

        $.each(captions, function() {
          var tween = TweenMax.from(this, 0.5, {opacity: 0, y: 50}),
          offset;

          if($(this).hasClass('flipbook')) {
            offset = 0;
          } else if($(this).hasClass('pullquote')) {
            offset = 100;
          } else {
            offset = 50;
          }

          var scene = new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: "onEnter",
            offset: offset
          }).setTween(tween)
            .addTo(controller);

        });

        /**
         * Set up scenes and pin Animations for each image sequence.
         */



        $.each(flipbookAnimation, function(index, value) {
          var first = $(this).find('.first-image img');
          first.css({transition : 'border 0.2s ease-in-out', boxSizing : 'content-box'});

          var trigger = $(this).parent()[0],
              allImages = $(this).find('.animated-image').not('.first-image'),
              sequence = $(this),
              numImages = allImages.length +1,
              tween = new TweenMax.staggerFrom($(allImages), 1, {autoAlpha: 0}, 1),
              duration = $(this).height() * (numImages/4);


          new ScrollMagic.Scene({triggerElement: trigger, triggerHook: "onLeave" , duration: duration})
            .setTween(tween)
            .addTo(controller);

          var pinScene = new ScrollMagic.Scene({triggerElement: trigger, triggerHook: "onLeave", duration: duration})
            .setPin(sequence[0])
            .addTo(controller);

          // animation tween - change background color on enter / exit

          var oldColor = null;
          var flipbookContainer = $('.animation-sequence');

          var colorFade = '#000';

          flipbookContainer.css({transition: 'background-color 0.2s ease-in-out'});

          pinScene.on('enter', function() {

            oldColor = flipbookContainer.css("backgroundColor");
            flipbookContainer.css({background: colorFade});

          });

          pinScene.on('leave', function() {

            flipbookContainer.css({background: oldColor});

          });

        });

      // end single project scripts
      }
    },

    'about': {
      init: function() {
        var player = new Vimeo.Player($('.video-container iframe')[0]);
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
